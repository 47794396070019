<template>
  <div :class="type">
    <shared-show :type="type" :inputs="inputs" :lefts="leftCards" :rights="rightCards" />
  </div>
</template>

<script>
import SharedShow from '@/components/shared/show.vue'

export default {
  name: 'AreaDetail',
  components: {
    SharedShow
  },
  data() {
    return {
      type: 'area',
      inputs: ['name', 'ordered', 'vrtour', 'country_content', 'vehicle_info', 'local_cuisine', 'travel_experience'],
      leftCards: ['groups'],
      rightCards: [
        'display',
        'address',
        'youtube',
        'audios',
        'pictures',
        'videos'
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
